<template>
  <eden-confirm-dialog
    title="Delete Menu"
    button-type="danger"
    button-text="Delete"
    :show.sync="setShow"
    @confirm="deleteIngredient"
  >
    <p>
      Are you sure you want to delete this ingredient,
      {{ formatText(ingredient.name) }}?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import ingredients from "@/requests/services/meal/ingredients";

export default {
  name: "IngredientDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ingredient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteIngredient() {
      const payload = {
        ingredient_ids: [this.ingredient.id],
      };
      ingredients
        .delete(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.ingredient.id);
            this.setShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.setShow = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
