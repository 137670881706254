<template>
  <div>
    <el-dialog
      :title="`${action === 'add' ? 'Add' : 'Edit'} New Ingredient`"
      :visible.sync="setShow"
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="ingredient">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Ingredient name"
              prop="name"
              :rules="validateField()"
            >
              <el-input type="text" v-model="form.name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item class="w-100">
              <div slot="label" class="label">
                <p>Measurement</p>
                <div @click="showMeasurementAdd = true">
                  <span class="text-primary">+ Add measurement</span>
                </div>
              </div>
              <el-select v-model="form.measurement">
                <el-option
                  v-for="(unit, index) in measurements"
                  :key="index"
                  :label="unit.name"
                  :value="unit.name.toLowerCase()"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button
          v-if="action === 'add'"
          type="primary"
          @click="add"
          :loading="adding"
          >Add</el-button
        >
        <el-button v-else type="primary" @click="update" :loading="updating"
          >Save changes</el-button
        >
      </span>
    </el-dialog>
    <measurement-add
      :show.sync="showMeasurementAdd"
      @success="getMeasurements"
    />
  </div>
</template>

<script>
import MeasurementAdd from "@/components/Services/Meal/Measurements/MeasurementAdd";
import ingredients from "@/requests/services/meal/ingredients";
import measurements from "@/requests/services/meal/measurements";

export default {
  name: "IngredientForm",
  components: {
    MeasurementAdd,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    ingredient: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        measurement: "",
      },
      showMeasurementAdd: false,
      measurements: [],
      adding: false,
      updating: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getMeasurements();
        if (this.action === "edit") {
          this.form.name = this.formatText(this.ingredient.name);
          this.form.measurement = this.ingredient.measurement;
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.form.name = "";
      this.form.measurement = "";
    },
    getMeasurements() {
      measurements.index().then((response) => {
        if (response.data.status) {
          this.measurements = this.sortList(response.data.data);
        }
      });
    },
    add() {
      this.$refs.ingredient.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        ingredients.add(this.form).then((response) => {
          if (response.data.status) {
            this.adding = false;
            this.$message.success(response.data.message);
            this.$emit("success", response.data.measurements);
            this.closeEvent();
          }
        });
        return true;
      });
    },
    update() {
      this.$refs.ingredient.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        ingredients.update(this.ingredient.id, this.form).then((response) => {
          if (response.data.status) {
            this.updating = false;
            this.$message.success(response.data.message);
            this.$emit("success", response.data.measurements);
            this.closeEvent();
          }
        });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
</style>
