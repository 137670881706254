<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <el-button
          v-if="allowAction"
          type="primary"
          @click="command({ action: 'add' })"
        >
          Add New Ingredient
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table v-if="ingredients.length" :data="ingredients">
        <el-table-column min-width="300">
          <template slot="header">
            <span> Name </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatText(scope.row.name) }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="300">
          <template slot="header">
            <span> Measurements </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatText(scope.row.measurement) }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="300">
          <template slot="header">
            <span> Date added </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatDateAndTime(scope.row.created_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAction" width="60">
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit-write"
                  :command="{
                    action: 'edit',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Edit ingredient</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Delete ingredient</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No ingredients'" />
    </template>
    <ingredient-form
      :show.sync="ingredient.visibility"
      :action="ingredient.action"
      :ingredient="ingredient.data"
      @success="getIngredients"
    />
    <ingredient-delete
      :show.sync="showIngredientDelete"
      :ingredient="ingredient.data"
      @success="removeIngredient"
    />
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import IngredientDelete from "@/components/Services/Meal/Ingredients/IngredientDelete";
import IngredientForm from "@/components/Services/Meal/Ingredients/IngredientForm";

export default {
  name: "Ingredients",
  components: { IngredientForm, IngredientDelete },
  props: {},
  data() {
    return {
      loading: false,
      ingredients: [],
      showIngredientDelete: false,
      ingredient: {
        action: "add",
        visibility: false,
        data: {},
      },
    };
  },

  computed: {
    title() {
      return `${this.ingredients.length} Ingredients`;
    },
    allowAction() {
      return this.allowAccessFor(["admin", "operations", "kitchen"]);
    },
  },
  created() {
    this.getIngredients();
  },
  methods: {
    getIngredients() {
      this.loading = true;
      this.$store.dispatch(actions.GET_INGREDIENTS).then(() => {
        this.loading = false;
        this.setCurrentingredients();
      });
    },
    setCurrentingredients() {
      this.ingredients = this.$store.getters.ingredients;
    },
    removeIngredient(id) {
      this.ingredients = this.ingredients.filter((menu) => menu.id !== id);
    },
    command(command) {
      switch (command.action) {
        case "add":
          this.ingredient.data = {};
          this.ingredient.action = "add";
          this.ingredient.visibility = true;
          break;
        case "edit":
          this.ingredient.data = this.ingredients[command.index];
          this.ingredient.action = "edit";
          this.ingredient.visibility = true;
          break;
        case "delete":
          this.ingredient.data = this.ingredients[command.index];
          this.showIngredientDelete = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
