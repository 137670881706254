var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title,"show-search":false}},[_c('template',{slot:"actions"},[(_vm.allowAction)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.command({ action: 'add' })}}},[_vm._v(" Add New Ingredient ")]):_vm._e()],1)],2),(_vm.loading)?_c('eden-loader'):[(_vm.ingredients.length)?_c('el-table',{attrs:{"data":_vm.ingredients}},[_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatText(scope.row.name)))])]}}],null,false,2512419746)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Name ")])])],2),_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatText(scope.row.measurement)))])]}}],null,false,3648738415)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Measurements ")])])],2),_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.created_at, "do m, y")))])]}}],null,false,2748720871)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Date added ")])])],2),(_vm.allowAction)?_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-edit-write","command":{
                  action: 'edit',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Edit ingredient")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Delete ingredient")])],1)],1)]}}],null,false,3569913592)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No ingredients'}})],_c('ingredient-form',{attrs:{"show":_vm.ingredient.visibility,"action":_vm.ingredient.action,"ingredient":_vm.ingredient.data},on:{"update:show":function($event){return _vm.$set(_vm.ingredient, "visibility", $event)},"success":_vm.getIngredients}}),_c('ingredient-delete',{attrs:{"show":_vm.showIngredientDelete,"ingredient":_vm.ingredient.data},on:{"update:show":function($event){_vm.showIngredientDelete=$event},"success":_vm.removeIngredient}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }