<template>
  <el-dialog title="Add New Measurement" :visible.sync="setShow" width="30%">
    <el-form :model="form" label-position="top" ref="measurement">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Measurement name"
            prop="name"
            :rules="validateField()"
          >
            <el-input type="text" v-model="form.name" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" @click="add" :loading="adding">Add</el-button>
    </span>
  </el-dialog>
</template>

<script>
import measurements from "@/requests/services/meal/measurements";

export default {
  name: "MeasurementAdd",
  props: {
    show: Boolean,
  },
  data() {
    return {
      form: {
        name: "",
      },
      adding: false,
    };
  },
  computed: {
    setShow: {
      get() {
        console.log("measurement");
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.form.name = "";
    },
    add() {
      this.$refs.measurement.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        measurements.add(this.form).then((response) => {
          if (response.data.status) {
            this.adding = false;
            this.$message.success(response.data.message);
            this.$emit("success", response.data.measurements);
            this.closeEvent();
          }
        });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
