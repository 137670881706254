import axios from "axios";

export default {
  index() {
    return axios.get("service_type/measurements/all");
  },

  add(payload) {
    return axios.post("service_type/measurements", payload);
  },
};
